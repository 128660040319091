import { PetBreed, PetType } from './petType.model';

export class Pet {
    public name!: string;
    public petType!: PetType;
    public petBreed?: PetBreed;
    public weight!: string;

    public constructor(init?: Partial<Pet>) {
        Object.assign(this, init);
    }
}
