export const environment = {
    production: false,
    env: 'dev',
    region: 'us-east-2',
    identityPoolID: 'us-east-2:3203cd6d-0dc3-421e-832a-11f8ab59261a',
    serviceUrl: 'https://c44q6ak2jfg55dwebuui3aopt4.appsync-api.us-east-2.amazonaws.com/graphql',
    calendarAvailabilitySearchThreshold: 1000,
    dsbNewRelicLicenseKey: '',
    dsbNewRelicApplicationID: '',
    dsbNewRelicAccountID: '',
    dsbNewRelicTrustKey: '',
};
