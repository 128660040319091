import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AppSyncResponse } from '@helpers/appSyncResponse.helper';
import { Service } from '@models/service.model';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { AppSyncService } from '../app-sync.service';
import { LocalDbService, LOCAL_DB_TABLE_NAME } from '../local-db.service';
import { CUSTOMER_ID, LocalStorageService } from '../local-storage.service';
import { NavigationService } from '../navigation.service';

@Injectable({
    providedIn: 'root',
})
export class ServiceApiService {
    private customerID;
    constructor(
        private appSyncService: AppSyncService,
        private localDbCache: LocalDbService,
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService
    ) {
        this.customerID = this.localStorageService.getString(CUSTOMER_ID);
    }

    public async getServiceList(): Promise<Service[]> {
        const serviceList = (await this.localDbCache.get(LOCAL_DB_TABLE_NAME.service, 'serviceList')) as Service[];
        if (serviceList) {
            return serviceList;
        }
        const client = this.appSyncService.getClient(environment.serviceUrl);

        const getServiceListQuery = gql`
            query getServiceList($daySmartAccountID: ID!) {
                getServiceList(input: { daySmartAccountID: $daySmartAccountID }) {
                    serviceList {
                        id
                        name
                        categoryList
                        employeeIDList
                        resourceIDList
                        duration
                    }
                }
            }
        `;
        this.navigationService.setIsLoading(true);
        const queryResult: ApolloQueryResult<AppSyncResponse> = await client.query({
            query: getServiceListQuery,
            variables: { daySmartAccountID: this.customerID },
            fetchPolicy: 'no-cache',
        });
        const modelList = Service.deserializeAppSyncResponse(queryResult);
        this.localDbCache.set(LOCAL_DB_TABLE_NAME.service, 'serviceList', modelList);
        this.navigationService.setIsLoading(false);
        return modelList;
    }
}
