import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterViewChecked, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { NavigationEnd, Router } from '@angular/router';
import { SyncAppointmentRequestDataResponse } from '@app/models/syncAppointmentRequest.model';
import { AppointmentRequestService } from '@app/services/appointment-request.service';
import { CUSTOMER_ID, LocalStorageService, SUPPORTS_PETS } from '@app/services/local-storage.service';
import { NavigationService, ScreenToShow } from '@services/navigation.service';
import { Subscription } from 'rxjs';

export enum ButtonName {
    SelectEmployee = 'Select Employee',
    SelectDateTime = 'Select Date/Time',
    SelectTime = 'Select Time',
    YourInformation = 'Your Information',
    PetInformation = 'Pet Information',
    ReviewDetails = 'Review Details',
    SubmitRequest = 'Submit Request',
}

export interface DsbStep {
    stepRoute: string;
    buttonText: string;
    index: number;
}

@Component({
    selector: 'content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, AfterViewChecked, OnDestroy {
    @HostBinding('class') classes = 'content-component-container';
    public areStepsCompleted = false;
    public isForwardButtonDisabled = true;
    public isBackButtonDisabled = true;
    private navigationSubscription!: Subscription;
    public isStepCompleted = 0;
    private dateTimeScreen!: ScreenToShow;
    public supportsPets: boolean = false;
    public clientInfoScreen: ScreenToShow = ScreenToShow.ScreenOne;
    ScreenToShow = ScreenToShow;
    public selectedStepIndex: number = 0;
    private customerID;
    private confirmationRoute = 'booking/submitted';
    private routerSubscription!: Subscription;
    @ViewChild('stepper', { static: true }) stepper!: MatStepper;
    buttonName: string = ButtonName.SelectEmployee;
    public steps: DsbStep[] = [
        {
            stepRoute: 'booking/service',
            buttonText: ButtonName.SelectEmployee,
            index: 0,
        },
        {
            stepRoute: 'booking/employee',
            buttonText: ButtonName.SelectDateTime,
            index: 1,
        },
        {
            stepRoute: 'booking/datetime',
            buttonText: ButtonName.SelectTime,
            index: 2,
        },
        {
            stepRoute: 'booking/client',
            buttonText: ButtonName.PetInformation,
            index: 3,
        },
        {
            stepRoute: 'booking/review',
            buttonText: ButtonName.SubmitRequest,
            index: 4,
        },
    ];
    constructor(
        private navigationService: NavigationService,
        private router: Router,
        private localStorageService: LocalStorageService,
        private cd: ChangeDetectorRef,
        private appointmentRequestService: AppointmentRequestService
    ) {
        this.customerID = this.localStorageService.getString(CUSTOMER_ID);
        this.supportsPets = this.localStorageService.get(SUPPORTS_PETS) as unknown as boolean;
        if (!this.supportsPets) {
            this.steps[3].buttonText = ButtonName.ReviewDetails;
        }
        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && !event.url.includes(this.confirmationRoute)) {
                const currentStep = this.steps.find((s) => event.url.includes(s.stepRoute));
                this.stepper.selectedIndex = currentStep ? currentStep.index : 0;
                this.selectedStepIndex = this.stepper.selectedIndex;
                this.isStepCompleted = this.selectedStepIndex;
                this.buttonName = currentStep ? currentStep.buttonText : this.steps[0].buttonText;
            }
        });
    }

    ngOnInit() {
        this.navigationSubscription = this.navigationService
            .getIsStepFormValid()
            .subscribe((isValid) => (this.isForwardButtonDisabled = !isValid));
        this.navigationSubscription = this.navigationService.getIsLoading().subscribe((isLoading) => {
            this.isBackButtonDisabled = !isLoading;
            if (this.isForwardButtonDisabled && !isLoading) {
                return;
            }
            this.isForwardButtonDisabled = isLoading;
        });
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    selectionChanged(event: StepperSelectionEvent) {
        this.isStepCompleted = event.previouslySelectedIndex > event.selectedIndex ? this.isStepCompleted-- : this.isStepCompleted++;
        this.navigationService.setNavigationBetweenDateAndTime(ScreenToShow.ScreenOne);
        const selectedStep = this.steps[event.selectedIndex];
        this.selectedStepIndex = event.selectedIndex;
        this.buttonName = selectedStep.buttonText;
        this.navigateToAnotherStep(selectedStep);
    }

    public navigateBack(buttonName: string) {
        if (buttonName === ButtonName.YourInformation) {
            this.navigationService.setNavigationBetweenDateAndTime(ScreenToShow.ScreenOne);
            this.navigationService.setIsStepFormValid(true);
            this.dateTimeScreen = ScreenToShow.ScreenOne;
            this.buttonName = ButtonName.SelectTime;
        } else if (buttonName === ButtonName.ReviewDetails && this.supportsPets) {
            this.clientInfoScreen = ScreenToShow.ScreenOne;
            this.buttonName = ButtonName.PetInformation;
            this.isForwardButtonDisabled = false;
        } else if (buttonName === ButtonName.SubmitRequest) {
            this.stepper.previous();
            this.clientInfoScreen = ScreenToShow.ScreenOne;
            this.buttonName = ButtonName.ReviewDetails;
        } else {
            this.stepper.previous();
            this.selectedStepIndex = this.stepper.selectedIndex;
            const selectedStep = this.steps[this.selectedStepIndex];
            this.buttonName = selectedStep.buttonText;
            this.navigateToAnotherStep(selectedStep);
        }
    }

    public async navigateForward(buttonName: string) {
        if (buttonName === ButtonName.SelectTime) {
            this.navigationService.setNavigationBetweenDateAndTime(ScreenToShow.ScreenTwo);
            this.dateTimeScreen = ScreenToShow.ScreenTwo;
            this.buttonName = ButtonName.YourInformation;
        } else if (buttonName === ButtonName.YourInformation) {
            this.clientInfoScreen = ScreenToShow.ScreenOne;
            this.navigationService.setNavigationButtonName(buttonName);
            this.stepper.next();
            this.navigationService.setNavigationBetweenDateAndTime(ScreenToShow.ScreenOne);
        } else if (buttonName === ButtonName.PetInformation) {
            this.navigationService.setNavigationButtonName(buttonName);
            this.buttonName = ButtonName.ReviewDetails;
            this.clientInfoScreen = ScreenToShow.ScreenTwo;
        } else if (buttonName === ButtonName.SubmitRequest) {
            try {
                const response = await this.createAppointmentRequest();
                this.navigateToAnotherStep(undefined, response);
            } catch (err) {
                throw new Error('Something failed');
            }
        } else {
            this.navigationService.setNavigationButtonName(buttonName);
            this.stepper.next();
            this.selectedStepIndex = this.stepper.selectedIndex;
            const selectedStep = this.steps[this.selectedStepIndex];
            this.buttonName = selectedStep.buttonText;
            this.navigateToAnotherStep(selectedStep);
        }
    }
    private navigateToAnotherStep(selectedStep?: DsbStep, response?: SyncAppointmentRequestDataResponse) {
        if (selectedStep) {
            this.router.navigate([selectedStep.stepRoute], {
                queryParams: {
                    DSID: this.customerID,
                },
            });
            this.isStepCompleted = selectedStep.index;
            return;
        }
        this.router.navigate(['/booking/submitted'], {
            queryParams: {
                DSID: this.customerID,
            },
            state: {
                response: response,
                shouldNavigate: true,
            },
        });
    }

    private async createAppointmentRequest() {
        return this.appointmentRequestService.createAppointmentRequest();
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
        this.navigationSubscription.unsubscribe();
    }
}
