import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import moment from 'moment';

interface CacheModel {
    key: string;
    value?: object;
    expires?: string;
}

export enum LOCAL_DB_TABLE_NAME {
    service = 'service',
    employee = 'employee',
    petType = 'petType',
    availabilities = 'availabilities',
}
/**
 * Number of minutes when the cache will expire
 */
export const DEFAULT_CACHE_LIFETIME = 5;

@Injectable({
    providedIn: 'root',
})
export class LocalDbService extends Dexie {
    private dsbTables = new Map<LOCAL_DB_TABLE_NAME, Dexie.Table<CacheModel, string>>();
    private tableInit: Promise<boolean>;
    public constructor() {
        super('DsbCacheDB');
        this.version(4).stores({
            service: '&key',
            employee: '&key',
            petType: '&key',
            availabilities: '&key',
        });
        this.tableInit = this.initTables();
    }
    private async initTables() {
        const promises = this.tables.map((table) => table.count());
        return await Promise.all(promises).then(() => {
            Object.values(LOCAL_DB_TABLE_NAME).forEach((tableName) => {
                this.dsbTables.set(tableName, this.table(tableName));
            });
            return true;
        });
    }

    /**
     * Checks whether a cache exists in a module
     * @param moduleName
     * @param key
     */
    public async has(moduleName: LOCAL_DB_TABLE_NAME, key: string) {
        await this.tableInit;
        const mapItem = await this.getMapItem(moduleName);
        const result = await mapItem?.get(key).then((res) => !!res);
        return result;
    }

    /**
     * Gets a cached value of a module.
     * You can pass in the type of objects to be returned but no validation will be performed on object type.
     * @param moduleName
     * @param key
     */
    public async get(moduleName: LOCAL_DB_TABLE_NAME, key: string) {
        await this.tableInit;
        const mapItem = await this.getMapItem(moduleName);
        const row = await mapItem?.get(key);
        if (row && (!row.expires || (row.expires && moment(row.expires).isAfter(moment())))) {
            return row.value;
        }
        return undefined;
    }

    /**
     * Sets a cached value of a module
     * @param moduleName
     * @param key
     * @param value
     */
    public async set(moduleName: LOCAL_DB_TABLE_NAME, key: string, value: object) {
        await this.tableInit;
        const mapItem = await this.getMapItem(moduleName);
        return mapItem?.put({
            key: key,
            value: value,
            expires: DEFAULT_CACHE_LIFETIME > 0 ? moment().add(DEFAULT_CACHE_LIFETIME, 'minutes').format('YYYY-MM-DDTHH:mm:ss') : undefined,
        });
    }

    /**
     * If both params are specified, clear specific cache keys from specified modules.
     * If only moduleNames is specified, clear all cache keys from these modules.
     * If only keys is specified, clear these keys from all modules.
     * If neither is specified, clear everything.
     * @param moduleNames
     * @param keys
     */
    public async clear(moduleNames?: LOCAL_DB_TABLE_NAME[], keys?: string[]): Promise<void> {
        await this.tableInit;
        if (!moduleNames) {
            moduleNames = Object.values(LOCAL_DB_TABLE_NAME);
        }
        for (const moduleName of moduleNames) {
            const mapItem = await this.getMapItem(moduleName);
            if (!keys) {
                mapItem?.clear();
            } else {
                mapItem?.bulkDelete(keys);
            }
        }
    }

    /**
     * Remove a cache by key from a module
     * @param moduleName
     * @param key
     */
    public async remove(moduleName: LOCAL_DB_TABLE_NAME, key: string): Promise<void> {
        await this.tableInit;
        const mapItem = await this.getMapItem(moduleName);
        mapItem?.delete(key);
    }

    /**
     * Remove a cache for all keys from a module
     * @param moduleName
     * @param key
     */
    public async removeAll(moduleName: LOCAL_DB_TABLE_NAME): Promise<void> {
        await this.tableInit;
        const mapItem = await this.getMapItem(moduleName);
        const mapItemValues = await mapItem?.toArray();
        if (!mapItemValues) {
            return;
        }
        for (const model of mapItemValues) {
            await mapItem?.delete(model.key);
        }
    }

    /**
     * Delete module cache witch are not in exclusion list
     * @param moduleName
     * @param forExclusion
     * @returns
     */
    public async clearWithExclusion(moduleName: LOCAL_DB_TABLE_NAME, forExclusion: string[]) {
        let keys: string[] = [];
        const mapItem = this.dsbTables.get(moduleName);
        if (!mapItem) {
            return;
        }
        const mapItemValues = await mapItem.toArray();
        mapItemValues.forEach((e) => {
            keys.push(e.key);
        });

        keys = keys.filter((e) => {
            return !forExclusion.includes(e);
        });

        if (keys.length > 0) {
            mapItem.bulkDelete(keys);
        }
    }

    private async getMapItem(moduleName: LOCAL_DB_TABLE_NAME) {
        const mapItem = this.dsbTables.get(moduleName);
        return mapItem;
    }
}
