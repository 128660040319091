<form [formGroup]="formGroup">
    <div class="step-container">
        <h1 qa="selectEmployeeTitle" class="step-title">Select Employee</h1>
        <div class="tp-radio-container">
            <mat-radio-group class="tp-radio-group" formControlName="employee">
                <mat-radio-button qa="selectEmployee" class="tp-radio-button" *ngFor="let employee of employeeList" [value]="employee.id">
                    {{ employee.name }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</form>
