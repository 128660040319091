import { AppSyncResponse } from '@helpers/appSyncResponse.helper';
import { ApolloQueryResult } from 'apollo-client';

export class PetBreed {
    public id: string;
    public name: string;
    public typeID: string;

    constructor(petBreed: PetBreed) {
        this.id = petBreed.id;
        this.name = petBreed.name;
        this.typeID = petBreed.typeID;
    }
}

export class PetType {
    public id: string;
    public name: string;
    public petBreedList: PetBreed[];

    constructor(petType: PetType) {
        this.id = petType.id;
        this.name = petType.name;
        this.petBreedList = petType.petBreedList;
    }

    public static deserializeAppSyncResponse(appSyncResponse: ApolloQueryResult<AppSyncResponse>): PetType[] {
        return AppSyncResponse.parseResponse(appSyncResponse.data, (obj: PetType[]) => obj.map((e: PetType) => e), [
            'getPetTypeList',
            'petTypeList',
        ]);
    }
}
