import { NativeDateAdapter } from '@angular/material/core';

export class CustomDateAdapter extends NativeDateAdapter {
    private dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    private shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    private monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    format(date: Date, displayFormat: any): string {
        const dayOfWeekIndex = date.getDay();
        const dayInMonth = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        if (displayFormat === 'ddd, MMM D, YYYY') {
            return this.dayNames[dayOfWeekIndex] + ', ' + this.shortMonthNames[monthIndex] + ' ' + dayInMonth + ', ' + year;
        }
        return this.monthNames[monthIndex] + ' ' + year;
    }

    getFirstDayOfWeek(): number {
        return 1;
    }

    getDayOfWeekNames() {
        return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    }
}
