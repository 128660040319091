import { NavigationService } from './../../../services/navigation.service';
import { Component, OnInit } from '@angular/core';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { SiteBookingSettings } from '@app/models/siteBookingSettings.model';
import { APPOINTMENT_REQUEST, LocalStorageService, SITE_BOOKING_SETTINGS, SUPPORTS_PETS } from '@app/services/local-storage.service';
import { convertTo12Hour, formatDateOutput } from '@app/shared/helpers/extensions';

@Component({
    selector: 'app-review-request',
    templateUrl: './review-request.component.html',
    styleUrls: ['./review-request.component.scss'],
})
export class ReviewRequestComponent implements OnInit {
    appointmentRequest!: AppointmentRequest;
    siteSettings!: SiteBookingSettings;
    supportsPets!: boolean;

    constructor(private localStorageService: LocalStorageService, private navigationService: NavigationService) {}

    ngOnInit() {
        this.getDataFromLocalStorage();
        if (this.siteSettings && this.appointmentRequest) {
            this.navigationService.setIsStepFormValid(true);
        }
    }

    private getDataFromLocalStorage() {
        this.siteSettings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
        this.appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        this.supportsPets = this.localStorageService.get(SUPPORTS_PETS) as unknown as boolean;
    }

    get getBookingDate() {
        return formatDateOutput(new Date(this.appointmentRequest.datetime));
    }

    get getBookingTime() {
        const tempDate = new Date(this.appointmentRequest.datetime);
        const hours = tempDate.getHours().toString().padStart(2, '0');
        const minutes = tempDate.getMinutes().toString().padStart(2, '0');
        const seconds = tempDate.getSeconds().toString().padStart(2, '0');
        const milliseconds = tempDate.getMilliseconds().toString().padStart(3, '0');

        return convertTo12Hour(`${hours}:${minutes}:${seconds}.${milliseconds}`, true);
    }
}
