import { Injectable } from '@angular/core';
import { EmployeeApiService } from '@api-services/employee-api.service';

@Injectable({
    providedIn: 'root',
})
export class EmployeeService {
    constructor(private employeeApiService: EmployeeApiService) {}

    public getEmployeeList(employeeIDList?: string[]) {
        return this.employeeApiService.getEmployeeList(employeeIDList);
    }
}
