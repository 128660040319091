<div class="time-step-datepicker">
    <form class="basic-container" [formGroup]="formGroup">
        <button
            mat-icon-button=""
            (click)="onPreviousClicked()"
            class="mat-focus-indicator button-previous mat-icon-button mat-button-base"
            [disabled]="previousEnabled() || isLoading"
            [ngClass]="{ 'disable-chevron-color': previousEnabled() || isLoading }"
        >
            <span class="mat-button-wrapper">
                <mat-icon role="img" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
                    >keyboard_arrow_left</mat-icon
                ></span
            >
        </button>
        <mat-form-field appearance="standard">
            <div class="center-datepicker">
                <input
                    matInput
                    readonly
                    [matDatepicker]="picker"
                    formControlName="picker"
                    [matDatepickerFilter]="filterDates"
                    (dateChange)="onDateChange($event)"
                    [min]="minDate"
                />
                <mat-datepicker-toggle (click)="onCalendarOpen()" disabled="false" matIconSuffix [for]="picker"></mat-datepicker-toggle>
            </div>
            <mat-datepicker #picker [calendarHeaderComponent]="customCalendarHeaderComponent"></mat-datepicker>
        </mat-form-field>
        <button
            mat-icon-button=""
            (click)="onNextClicked()"
            class="mat-focus-indicator button-next mat-icon-button mat-button-base"
            [disabled]="isLoading"
            [ngClass]="{ 'disable-chevron-color': isLoading }"
        >
            <span class="mat-button-wrapper"
                ><mat-icon role="img" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
                    >keyboard_arrow_right</mat-icon
                ></span
            >
        </button>
    </form>
</div>
