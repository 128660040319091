import { ErrorComponent } from './components/error/error.component';
import { TimeStepDatePickerComponent } from './components/booking-steps/select-date-time/time-step-date-picker/time-step-date-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceApiService } from '@services/api/service-api.service';
import { CustomDateAdapter } from '@services/custom-date-adapter';
import { NavigationService } from '@services/navigation.service';
import { LottieModule } from 'ngx-lottie';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppMaterialModule } from './app.md-module';
import { AddServiceComponent } from './components/booking-steps/add-service/add-service.component';
import { EnterYourInformationComponent } from './components/booking-steps/enter-your-information/enter-your-information.component';
import { PetInformationComponent } from './components/booking-steps/pet-information/pet-information.component';
import { ReviewRequestComponent } from './components/booking-steps/review-request/review-request.component';
import { CustomCalendarHeaderComponent } from './components/booking-steps/select-date-time/custom-calendar-header/custom-calendar-header.component';
import { SelectDateTimeComponent } from './components/booking-steps/select-date-time/select-date-time.component';
import { SelectEmployeeComponent } from './components/booking-steps/select-employee/select-employee.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ContentComponent } from './components/content/content.component';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { AppSyncService } from './services/app-sync.service';
import { ThemeService } from './services/theme.service';
import { HttpClientModule } from '@angular/common/http';
import { HorizontalScrollDirective } from './directives/horizontal-scroll.directive';

export function playerFactory() {
    return import('lottie-web');
}

@NgModule({
    declarations: [
        AppComponent,
        TitleBarComponent,
        ContentComponent,
        AddServiceComponent,
        SelectEmployeeComponent,
        SelectDateTimeComponent,
        EnterYourInformationComponent,
        ReviewRequestComponent,
        ConfirmationComponent,
        PetInformationComponent,
        CustomCalendarHeaderComponent,
        TimeStepDatePickerComponent,
        ErrorComponent,
        HorizontalScrollDirective,
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AppMaterialModule,
        LottieModule.forRoot({ player: playerFactory }),
        FormsModule,
        ReactiveFormsModule,
    ],
    providers: [
        ThemeService,
        NavigationService,
        ServiceApiService,
        AppSyncService,
        { provide: DateAdapter, useClass: CustomDateAdapter },
        {
            provide: APP_INITIALIZER,
            // Provide the APP_INITIALIZER, wait until the theming configuration is fetched and set up correctly
            useFactory: (themingService: ThemeService) => () => themingService.initialize(),
            deps: [ThemeService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
