export class Client {
    public firstName!: string;
    public lastName!: string;
    public email!: string;
    public phoneNumber!: string;
    public enableEmailMarketing!: boolean;
    public enableTextMarketing!: boolean;

    public constructor(init?: Partial<Client>) {
        Object.assign(this, init);
    }
}
