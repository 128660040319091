import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import * as tinycolor from 'tinycolor2';
import { PetApiService } from './api/pet-api.service';
import { LocalDbService } from './local-db.service';
import { CUSTOMER_ID, LocalStorageService, SITE_BOOKING_SETTINGS, SUPPORTS_PETS } from './local-storage.service';
import { SiteService } from './site.service';

export interface ThemeConfig {
    primaryColor: string;
}

export interface ColorConfig {
    colorVariant: string;
    colorHexValue: string;
    shouldHaveDarkContrast: boolean;
}

export enum ThemeMainColorType {
    primaryColor = 'primaryColor',
}

@Injectable()
export class ThemeService implements OnDestroy {
    private static readonly THEME_VARIABLE_PREFIX = '--theme';
    // Allows listening to theme changes
    currentTheme: Subject<ThemeConfig> = new Subject();
    private readonly renderer: Renderer2;

    constructor(
        private rendererFactory: RendererFactory2,
        private localStorageService: LocalStorageService,
        private siteService: SiteService,
        private petApiService: PetApiService,
        private localDbCache: LocalDbService,
        private router: Router
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    ngOnDestroy(): void {
        this.renderer?.destroy();
    }

    /**
     * The load method must return a Promise, since that will make the application wait in the APP_INITIALIZER DI token
     * After all the loading and set up is finished, we can proceed with rendering the application
     */
    async initialize(): Promise<any> {
        const url = new URL(window.location.href);
        const daySmartAccountID = url.searchParams.get('DSID');
        if (daySmartAccountID) {
            try {
                this.subscribeToThemeChanges();
                const daySmartAccountIdFromStorage = this.localStorageService.getString(CUSTOMER_ID);
                const isDaySmartAccountIdDifferent = daySmartAccountIdFromStorage && daySmartAccountID !== daySmartAccountIdFromStorage;
                if (!daySmartAccountIdFromStorage || isDaySmartAccountIdDifferent) {
                    this.localDbCache.clear();
                }
                this.localStorageService.setString(CUSTOMER_ID, daySmartAccountID);
                const siteBookingSettings = await this.siteService.getSiteBookingSettings(daySmartAccountID);
                this.localStorageService.set(SITE_BOOKING_SETTINGS, siteBookingSettings);
                const isPet = await this.siteService.getSite(daySmartAccountID);
                this.localStorageService.set(SUPPORTS_PETS, isPet);
                if (isPet) {
                    this.petApiService.setPetTypeListToTheLS(daySmartAccountID);
                }
                const theme = siteBookingSettings.themeColor;
                this.currentTheme.next({ primaryColor: theme });
            } catch (error: any) {
                console.log(error);
                if (error.message.includes(`A site with the provided DaySmart account id doesn't exist`)) {
                    this.localStorageService.remove(CUSTOMER_ID);
                }
                this.router.navigate(['/booking/invalid']);
            }
        } else {
            console.log('No daySmartAccountID');
            this.router.navigate(['/booking/invalid']);
        }
    }

    private subscribeToThemeChanges(): void {
        this.currentTheme.subscribe((themeConfig: ThemeConfig) => {
            this.setupMainPalettes(themeConfig);
        });
    }

    /**
     * This method will generate the theme palette required by Angular Material
     * It will correctly set up the variable names used in /assets/styles/_variables.scss
     * @param themeConfig
     * @private
     */
    private setupMainPalettes(themeConfig: ThemeConfig): void {
        const selectedColorValue: string = themeConfig.primaryColor;

        // Should be for example: --theme-primary or --theme-accent etc..
        const variableName: string = this.prependVariableName(this.convertCamelCaseToKebabCase(ThemeMainColorType.primaryColor));

        // Generate the palette colors
        const colorPalette: Array<ColorConfig> = this.generateColorPalette(selectedColorValue);

        colorPalette.forEach((colorConfig: ColorConfig) => {
            // Destructure the color config
            const { colorVariant, colorHexValue, shouldHaveDarkContrast } = colorConfig;

            // Set the color variable
            const colorVariableName = `${variableName}-${colorVariant}`;
            this.setColorVariable(colorVariableName, colorHexValue);

            // By Angular material, contrasted colors are either white, or a darker color
            // Set the contrast color
            const contrastedColorVariableName = `${variableName}-contrast-${colorVariant}`;
            const contrastedColorValue = shouldHaveDarkContrast ? 'rgba(0, 0, 0, 0.87)' : '#fff';
            this.setColorVariable(contrastedColorVariableName, contrastedColorValue);
        });
    }

    /**
     * This method generates a color palette comprised of 14 main and 14 contrast colors per the Angular material specification
     * It will allow us to have different shades of some color and we can use all of those shades in our material and non-material
     * components via css.
     * The configuration can never be 100% accurate to the Material stock colors, as they are sometimes hand-made by a designer
     * So this calculation will never be 100% accurate to the original colors provided in the Material design CSS files
     * @param hexColor
     * @private
     */
    private generateColorPalette(hexColor: string): Array<ColorConfig> {
        const baseLight = tinycolor('#ffffff');
        const baseDark = this.multiply(tinycolor(hexColor).toRgb(), tinycolor(hexColor).toRgb());
        const baseTriad = tinycolor(hexColor).tetrad();

        return [
            this.mapColorConfig(tinycolor.mix(baseLight, hexColor, 12), '50'),
            this.mapColorConfig(tinycolor.mix(baseLight, hexColor, 30), '100'),
            this.mapColorConfig(tinycolor.mix(baseLight, hexColor, 50), '200'),
            this.mapColorConfig(tinycolor.mix(baseLight, hexColor, 70), '300'),
            this.mapColorConfig(tinycolor.mix(baseLight, hexColor, 85), '400'),
            this.mapColorConfig(tinycolor.mix(baseLight, hexColor, 100), '500'),
            this.mapColorConfig(tinycolor.mix(baseDark, hexColor, 87), '600'),
            this.mapColorConfig(tinycolor.mix(baseDark, hexColor, 70), '700'),
            this.mapColorConfig(tinycolor.mix(baseDark, hexColor, 54), '800'),
            this.mapColorConfig(tinycolor.mix(baseDark, hexColor, 25), '900'),
            this.mapColorConfig(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(80).lighten(65), 'A100'),
            this.mapColorConfig(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(80).lighten(55), 'A200'),
            this.mapColorConfig(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(100).lighten(45), 'A400'),
            this.mapColorConfig(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(100).lighten(40), 'A700'),
        ];
    }

    /**
     * Map the color and its variant to something that we understand
     * Also check if we need to use a light or dark contrast color
     * @param tinyColorInstance
     * @param colorVariant
     * @private
     */
    private mapColorConfig(tinyColorInstance: tinycolor.Instance, colorVariant: string): ColorConfig {
        return {
            colorVariant,
            colorHexValue: tinyColorInstance.toHexString(),
            shouldHaveDarkContrast: tinyColorInstance.isLight(),
        };
    }

    private multiply(rgb1: tinycolor.ColorFormats.RGB, rgb2: tinycolor.ColorFormats.RGB): tinycolor.Instance {
        rgb1.r = Math.floor((rgb1.r * rgb2.r) / 255);
        rgb1.g = Math.floor((rgb1.g * rgb2.g) / 255);
        rgb1.b = Math.floor((rgb1.b * rgb2.b) / 255);
        const { r, g, b } = rgb1;

        return tinycolor(`rgb ${r} ${g} ${b}`);
    }

    private prependVariableName(key: string): string {
        return `${ThemeService.THEME_VARIABLE_PREFIX}-${key}`;
    }

    /**
     * Change a camelCase variable to a kebab case
     * e.g: primaryColor -> primary-color
     * @param key
     * @private
     */
    private convertCamelCaseToKebabCase(key: string): string {
        return key.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
    }

    private setColorVariable(variable: string, color: string): void {
        document.documentElement.style.setProperty(variable, color);
    }
}
