import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonName } from '@app/components/content/content.component';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { Service } from '@app/models/service.model';
import { APPOINTMENT_REQUEST, LocalStorageService } from '@app/services/local-storage.service';
import { NavigationService } from '@app/services/navigation.service';
import { Employee } from '@models/employee.model';
import { EmployeeService } from '@services/employee.service';
import { from, Subject, Subscription, takeUntil } from 'rxjs';

@Component({
    selector: 'app-select-employee',
    templateUrl: './select-employee.component.html',
    styleUrls: ['./select-employee.component.scss'],
})
export class SelectEmployeeComponent implements OnInit, OnDestroy {
    employeeList: Employee[] = [];
    public formGroup: FormGroup;
    public ngUnsubscribe: Subject<any> = new Subject();
    private _destroyed$: Subject<void>;
    private navigationButtonSubscription!: Subscription;

    constructor(
        private employeeService: EmployeeService,
        private fb: FormBuilder,
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService
    ) {
        this._destroyed$ = new Subject();
        this.formGroup = this.fb.group({
            employee: new FormControl('', Validators.required),
        });
    }

    ngOnInit() {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        const selectedService = appointmentRequest && appointmentRequest.service.length ? appointmentRequest.service[0] : undefined;
        this.fetchEmployees(selectedService);

        /* istanbul ignore next */
        this.navigationButtonSubscription = this.navigationService.getNavigationButtonName().subscribe((buttonName) => {
            if (buttonName === ButtonName.SelectDateTime) {
                const employee = this.employeeList.filter((e) => e.id === this.formGroup.value.employee);
                this.localStorageService.setNested(APPOINTMENT_REQUEST, 'employee', employee);
            }
        });
        this.formGroup.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status) => {
            if (status === 'VALID') {
                this.navigationService.setIsStepFormValid(true);
            }
        });
        this.previouslySelectedEmployee();
    }

    private fetchEmployees(service?: Service) {
        from(this.employeeService.getEmployeeList(service?.employeeIDList))
            .pipe(takeUntil(this._destroyed$))
            .subscribe((employeeList) => {
                this.employeeList = employeeList;
                this.formGroup.updateValueAndValidity();
            });
    }
    private previouslySelectedEmployee() {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        if (appointmentRequest?.employee?.length > 0) {
            this.formGroup.patchValue({ employee: appointmentRequest.employee[0].id });
            this.navigationService.setIsStepFormValid(true);
        } else {
            this.navigationService.setIsStepFormValid(false);
        }
    }
    ngOnDestroy() {
        this.navigationButtonSubscription.unsubscribe();
        this.ngUnsubscribe.complete();
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
