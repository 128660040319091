import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonName } from '@app/components/content/content.component';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { APPOINTMENT_REQUEST, LocalStorageService } from '@app/services/local-storage.service';
import { NavigationService } from '@app/services/navigation.service';
import { Service } from '@models/service.model';
import { ServiceService } from '@services/service.service';
import { from, Subject, Subscription, takeUntil } from 'rxjs';

@Component({
    selector: 'app-add-service',
    templateUrl: './add-service.component.html',
    styleUrls: ['./add-service.component.scss'],
})
export class AddServiceComponent implements OnInit, OnDestroy {
    public formGroup: FormGroup;
    public ngUnsubscribe: Subject<any> = new Subject();
    private _destroyed$: Subject<void>;
    private navigationButtonSubscription!: Subscription;

    serviceList: Service[] = [];
    categoryList: string[] = [];
    filteredServiceList: Service[] = [];
    defaultValue = 'All';
    selectedCategory: string;

    constructor(
        private serviceService: ServiceService,
        private fb: FormBuilder,
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService,
        private cdRef: ChangeDetectorRef
    ) {
        this._destroyed$ = new Subject();
        this.selectedCategory = this.defaultValue;
        this.formGroup = this.fb.group({
            service: new FormControl('', Validators.required),
        });
    }

    ngOnInit() {
        this.fetchServices();
        /* istanbul ignore next */
        this.navigationButtonSubscription = this.navigationService.getNavigationButtonName().subscribe((buttonName) => {
            if (buttonName === ButtonName.SelectEmployee) {
                const service = this.serviceList.filter((s) => s.id === this.formGroup.value.service);
                this.localStorageService.setNested(APPOINTMENT_REQUEST, 'service', service);
            }
        });
        this.formGroup.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status) => {
            if (status === 'VALID') {
                this.navigationService.setIsStepFormValid(true);
            }
        });
        this.previouslySelectedService();
    }

    private fetchServices() {
        from(this.serviceService.getServiceList())
            .pipe(takeUntil(this._destroyed$))
            .subscribe((serviceList) => {
                this.serviceList = serviceList;
                this.filteredServiceList = this.serviceList;
                this.getCategories();
                this.formGroup.updateValueAndValidity();
            });
    }

    private async getCategories() {
        let categories: string[] = [];
        this.serviceList.forEach(function (service) {
            categories.push(...service.categoryList);
        });
        categories = categories.filter((c) => c !== '');
        this.categoryList = [...new Set(this.categoryList.concat(categories))];
        this.categoryList.unshift(this.defaultValue);
        this.shoudHideRightArrow();
    }

    /* istanbul ignore next */
    private shoudHideRightArrow() {
        this.cdRef.detectChanges();
        const arrowIcons = document.querySelectorAll('.icon .mat-icon');
        const toggleGroup = document.querySelector('.horizontal-scroll-box') as HTMLElement;

        if (!arrowIcons[1].parentElement) {
            return;
        }

        const maxScrollableWidth = toggleGroup.scrollWidth - toggleGroup.clientWidth;
        const isAtEnd = maxScrollableWidth <= 0;
        arrowIcons[1].parentElement.style.display = isAtEnd ? 'none' : 'flex';
    }

    public onFilterValueChange($event: any) {
        if ($event.value !== this.defaultValue) {
            this.filteredServiceList = this.serviceList.filter((s) => s.categoryList.includes($event.value));
        } else {
            this.filteredServiceList = this.serviceList;
        }
        this.selectedCategory = $event.value;
    }

    limitMaxLength(val: string) {
        return val?.length > 40 ? val.substring(0, 40) + '...' : val;
    }

    private previouslySelectedService() {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        if (appointmentRequest?.service?.length > 0) {
            this.formGroup.patchValue({ service: appointmentRequest.service[0].id });
            this.selectCategoryAndScrollToSelectedService(appointmentRequest.service[0]);
            this.navigationService.setIsStepFormValid(true);
        } else {
            this.navigationService.setIsStepFormValid(false);
        }
    }

    private selectCategoryAndScrollToSelectedService(selectedService: Service) {
        setTimeout(() => {
            const categoryToSelect = this.categoryList.filter((category) => selectedService.categoryList.includes(category))[0];
            this.selectedCategory = categoryToSelect ?? this.defaultValue;
            this.onFilterValueChange({ value: this.selectedCategory });
            const id = `service-${selectedService.id}`;
            const element = document.getElementById(id) as HTMLElement;
            /* istanbul ignore next */
            element?.scrollIntoView({ behavior: 'smooth' });
        }, 500);
    }

    ngOnDestroy() {
        this.navigationButtonSubscription.unsubscribe();
        this.ngUnsubscribe.complete();
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
