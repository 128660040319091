import { Injectable } from '@angular/core';
import { Availability } from '@app/models/availability.model';
import { AppSyncResponse } from '@app/shared/helpers/appSyncResponse.helper';
import { environment } from '@environments/environment';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { AppSyncService } from '../app-sync.service';
import { LocalDbService, LOCAL_DB_TABLE_NAME } from '../local-db.service';
import { APPOINTMENT_REQUEST, CUSTOMER_ID, LocalStorageService } from '../local-storage.service';
import { AppointmentRequest } from './../../models/appointmentRequest.model';
import { NavigationService } from './../navigation.service';

@Injectable({
    providedIn: 'root',
})
export class AvailabilitiesApiService {
    constructor(
        private appSyncService: AppSyncService,
        private localDbCache: LocalDbService,
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService
    ) {}

    async getAvailabilities(dateStart: string, dateEnd: string, showSpinner: boolean): Promise<Availability[]> {
        const availabilitiesList = (await this.localDbCache.get(
            LOCAL_DB_TABLE_NAME.availabilities,
            'availabilitiesList'
        )) as Availability[];
        if (
            availabilitiesList &&
            availabilitiesList.length > 0 &&
            availabilitiesList.filter((a) => a.start.includes(dateStart.split('T')[0].substring(0, dateStart.lastIndexOf('-')))).length
        ) {
            return availabilitiesList;
        }
        const client = this.appSyncService.getClient(environment.serviceUrl);

        const getAvailabilitiesQuery = gql`
            query getAvailabilitiesList(
                $daySmartAccountID: ID!
                $employeeId: ID!
                $resourceIDList: [ID]
                $duration: Int!
                $dateStart: String!
                $dateEnd: String
                $accountForBookingInAdvance: Boolean
            ) {
                getAvailabilitiesList(
                    input: {
                        daySmartAccountID: $daySmartAccountID
                        employeeID: $employeeId
                        resourceIDList: $resourceIDList
                        duration: $duration
                        dateStart: $dateStart
                        dateEnd: $dateEnd
                        accountForBookingInAdvance: $accountForBookingInAdvance
                    }
                ) {
                    availabilitiesList {
                        employeeID
                        end
                        resourceIDList
                        start
                    }
                }
            }
        `;

        const daySmartAccountID = this.localStorageService.getString(CUSTOMER_ID);
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        const employeeId = appointmentRequest.employee[0].id;
        const duration = appointmentRequest.service[0].duration;
        const accountForBookingInAdvance = true;
        const resourceIDList = appointmentRequest.service[0].resourceIDList;
        if (showSpinner) {
            this.navigationService.setIsLoading(true);
        }
        const queryResult: ApolloQueryResult<AppSyncResponse> = await client.query({
            query: getAvailabilitiesQuery,
            variables: { daySmartAccountID, employeeId, resourceIDList, duration, dateStart, dateEnd, accountForBookingInAdvance },
            fetchPolicy: 'no-cache',
        });

        const modelList = Availability.deserializeAppSyncResponse(queryResult);
        this.localDbCache.set(LOCAL_DB_TABLE_NAME.availabilities, 'availabilitiesList', modelList);
        if (showSpinner) {
            this.navigationService.setIsLoading(false);
        }

        return modelList;
    }
}
