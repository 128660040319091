<!-- eslint-disable max-len -->
<form [formGroup]="formGroup">
    <div class="step-container">
        <div class="step-fixed-part">
            <h1 qa="selectService" class="step-title">Select Service</h1>
            <div class="horizontal-scroll-container">
                <div class="icon" (click)="horScrollDirective.scrollOnClick('left')">
                    <mat-icon class="material-icons-round margin-left">keyboard_arrow_left</mat-icon>
                </div>
                <mat-button-toggle-group
                    #toggleGroup
                    class="horizontal-scroll-box"
                    [value]="selectedCategory"
                    [horizontalScroll]="'mat-button-toggle-group'"
                    #horScrollDirective="horizontalScroll"
                >
                    <mat-button-toggle
                        class="toggle-button"
                        (change)="onFilterValueChange($event)"
                        *ngFor="let category of categoryList"
                        value="{{ category }}"
                        qa="selectService"
                    >
                        {{ limitMaxLength(category) }}
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <div class="icon" (click)="horScrollDirective.scrollOnClick('right')">
                    <mat-icon class="material-icons-round margin-right">keyboard_arrow_right</mat-icon>
                </div>
            </div>
            <h2 class="step-subtitle">
                Services: <span>{{ filteredServiceList.length }}</span>
            </h2>
        </div>
        <div class="tp-radio-container">
            <mat-radio-group class="tp-radio-group" formControlName="service">
                <mat-radio-button
                    class="tp-radio-button"
                    *ngFor="let service of filteredServiceList"
                    id="service-{{ service.id }}"
                    value="{{ service.id }}"
                    qa="selectTypeService"
                >
                    {{ service.name }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</form>
