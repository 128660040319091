import { SiteBookingSettings } from './../../models/siteBookingSettings.model';
import { LocalStorageService, SITE_BOOKING_SETTINGS } from '@app/services/local-storage.service';
import { Component, OnInit, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '@app/services/navigation.service';

export enum WarningScreenType {
    InvalidUrlScreen,
    ErrorScreen,
}

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
    private invalidURLMainTitle = `Oops, that address doesn't look right.`;
    private invalidURLSubtext = 'Please verify that the url is correct and try again.';
    private errorScreenMainTitle = 'Oops, something went wrong...';
    private errorScreenURLSubtext = 'If you would still like to schedule an appointment, please contact us at: ';

    screenMainTitle = '';
    screenSubtext = '';
    screenType: WarningScreenType | undefined;
    siteSettings: SiteBookingSettings | undefined;
    businessPhoneNumber = '';
    phoneNumberExists = false;

    constructor(private router: Router, private localStorageService: LocalStorageService, private navigationService: NavigationService) {}

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    @HostBinding('class.error-container')
    ngOnInit() {
        this.navigationService.setIsLoading(false);
        this.resolveScreenType(this.router.url);
    }

    private resolveScreenType(url: string) {
        if (url.includes('invalid')) {
            this.screenMainTitle = this.invalidURLMainTitle;
            this.screenSubtext = this.invalidURLSubtext;
            this.screenType = WarningScreenType.InvalidUrlScreen;
        } else {
            this.siteSettings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
            this.screenMainTitle = this.errorScreenMainTitle;
            this.screenSubtext = this.errorScreenURLSubtext;
            this.screenType = WarningScreenType.ErrorScreen;
            if (this.siteSettings && this.siteSettings.businessPhoneNumber) {
                this.phoneNumberExists = true;
                this.businessPhoneNumber = this.siteSettings.businessPhoneNumber;
            } else {
                this.screenSubtext = this.screenSubtext.replace('us at: ', 'the business.');
            }
        }
    }

    public get WarningScreenType() {
        return WarningScreenType;
    }
}
