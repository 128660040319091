<div class="page-container">
    <div class="content-container">
        <div class="content">
            <mat-horizontal-stepper #stepper [disableRipple]="true" (selectionChange)="selectionChanged($event)">
                <mat-step state="edit" [completed]="isStepCompleted > 0">
                    <app-add-service *ngIf="stepper.selectedIndex === 0"></app-add-service>
                </mat-step>
                <mat-step state="edit" [completed]="isStepCompleted > 1">
                    <app-select-employee *ngIf="stepper.selectedIndex === 1"></app-select-employee>
                </mat-step>
                <mat-step state="edit" [completed]="isStepCompleted > 2">
                    <app-select-date-time *ngIf="stepper.selectedIndex === 2"></app-select-date-time>
                </mat-step>
                <mat-step state="edit" [completed]="isStepCompleted > 3">
                    <app-enter-your-information
                        *ngIf="stepper.selectedIndex === 3 && clientInfoScreen === ScreenToShow.ScreenOne"
                        [disabled]="clientInfoScreen !== ScreenToShow.ScreenOne"
                    ></app-enter-your-information>
                    <app-pet-information
                        *ngIf="stepper.selectedIndex === 3 && clientInfoScreen === ScreenToShow.ScreenTwo && supportsPets"
                        [disabled]="clientInfoScreen !== ScreenToShow.ScreenTwo"
                    ></app-pet-information>
                </mat-step>
                <mat-step state="edit" [completed]="isStepCompleted > 4">
                    <app-review-request *ngIf="stepper.selectedIndex === 4"></app-review-request>
                </mat-step>
                <ng-template matStepperIcon="edit">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.1152 0C18.7427 0 24.1152 5.37258 24.1152 12C24.1152 18.6274 18.7427 24 12.1152 24C5.48781 24 0.115234
                                 18.6274 0.115234 12C0.115234 5.37258 5.48781 0 12.1152 0Z"
                        />
                        <path
                            d="M9.53528 15.59L6.71393 12.372C6.39955 12.0134 5.89977 12.0134 5.58539 12.372C5.27101 12.7306 5.27101
                                 13.3006 5.58539 13.6592L8.96295 17.5116C9.27733 17.8701 9.78517 17.8701 10.0996 17.5116L18.6442
                                  7.77487C18.9586 7.41629 18.9586 6.84625 18.6442 6.48768C18.3298 6.12911 17.8301 6.12911 17.5157
                                   6.48768L10.4439 14.5536L9.53528 15.59Z"
                            fill="white"
                        />
                        <path opacity="0.5" d="M12.1152 32V56" stroke="#3C6CE7" />
                    </svg>
                </ng-template>
            </mat-horizontal-stepper>
        </div>
        <div class="button-container">
            <ng-container *ngIf="stepper.selectedIndex !== 0">
                <button
                    id="back-button"
                    [disabled]="!isBackButtonDisabled"
                    mat-stroked-button
                    (click)="navigateBack(buttonName)"
                    qa="backButton"
                >
                    Back
                </button>
            </ng-container>
            <button
                id="forward-button"
                [disabled]="isForwardButtonDisabled"
                mat-button
                class="primary"
                name="forwardButton"
                (click)="navigateForward(buttonName)"
                qa="forwardButton"
            >
                {{ buttonName }}
                <svg class="forward-button-arrow" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.456562 0.709778C0.0665625 1.09978 0.0665625 1.72978 0.456562 2.11978L4.33656
                         5.99978L0.456562 9.87978C0.0665625 10.2698 0.0665625 10.8998 0.456562 11.2898C0.846563
                          11.6798 1.47656 11.6798 1.86656 11.2898L6.45656 6.69978C6.84656 6.30978 6.84656 5.67978
                           6.45656 5.28978L1.86656 0.699778C1.48656 0.319778 0.846563 0.319778 0.456562 0.709778Z"
                        fill="white"
                    />
                </svg>
            </button>
        </div>
    </div>
</div>
