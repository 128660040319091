<div>
    <svg width="93" height="83" viewBox="0 0 93 83" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_890_2977)">
            <path
                d="M91.1045 78.609C89.5551 81.2885 86.6754 82.9531 83.5842 82.9531H8.9431C5.85195 82.9531 
                2.96842 81.2885 1.42285 78.6128C-0.122721 75.9333 -0.122721 72.6041 1.42285 69.9246L38.7396 
                5.2934C40.2852 2.61388 43.1648 0.953125 46.2598 0.953125C49.3548 0.953125 52.2345 2.61773 
                53.7801 5.2934L91.0968 69.9246C92.6424 72.6003 92.6424 75.9295 91.0968 78.609H91.1045Z"
                [ngClass]="{
                    'invalid-color': screenType === WarningScreenType.InvalidUrlScreen,
                    'error-color': screenType === WarningScreenType.ErrorScreen
                }"
            />
            <path
                d="M51.9351 69.519C53.2328 66.3864 51.7451 62.795 48.6122 61.4975C45.4793 60.1999 41.8876 
                61.6875 40.5899 64.8201C39.2922 67.9527 40.78 71.5441 43.9129 72.8416C47.0458 74.1392 50.6375 72.6516 51.9351 69.519Z"
                fill="white"
            />
            <path
                d="M46.2632 57.4344C44.114 57.4344 42.3262 55.7737 42.1724 53.6285L40.0924 24.6921C39.8348 
                21.113 42.6722 18.0645 46.2593 18.0645C49.8503 18.0645 52.6838 21.1092 52.4262 24.6921L50.3463 
                53.6285C50.1925 55.7737 48.4085 57.4344 46.2555 57.4344H46.2632Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_890_2977">
                <rect width="92" height="82" fill="white" transform="translate(0.261719 0.953125)" />
            </clipPath>
        </defs>
    </svg>
</div>
<div class="error-message">
    <p>{{ screenMainTitle }}</p>
</div>
<div class="error-message-subtext">
    <p>
        {{ screenSubtext }}
        <a
            *ngIf="screenType === WarningScreenType.ErrorScreen && phoneNumberExists; else phoneNumberMissing"
            href="tel:{{ businessPhoneNumber }}"
        >
            {{ businessPhoneNumber }}
        </a>
        <ng-template #phoneNumberMissing>{{ businessPhoneNumber }}</ng-template>
    </p>
</div>
