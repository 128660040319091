import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationService } from './services/navigation.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    public isLoading = false;
    private navigationSubscription!: Subscription;

    constructor(private navigationService: NavigationService, private changeDetector: ChangeDetectorRef) {
        this.setWindowsHeight();
        window.addEventListener('resize', this.setWindowsHeight);
    }

    ngOnInit(): void {
        this.navigationSubscription = this.navigationService.getIsLoading().subscribe((isLoading) => {
            if (this.isLoading !== isLoading) {
                this.isLoading = isLoading;
                this.changeDetector.detectChanges();
            }
        });
    }

    private setWindowsHeight() {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--windows-height', `${vh}px`);
    }
    ngOnDestroy() {
        this.navigationSubscription.unsubscribe();
    }
}
